$().ready(function(){
    var driver = 0; // 1 = cookie, 0 = localStorage
    function get(key){
        return driver ? $.cookie(key) : localStorage.getItem(key)
    }
    function set(key,value){
        return driver ? $.cookie(key,value) : localStorage.setItem(key,value)
    }

    /* traid in */
    var addClearFix = 0;
    var addClearFixCeil = 0;
    $(".trade_in_item").each(function(){
        addClearFix += 1;
        addClearFixCeil = addClearFix/2;
        if ( parseInt(addClearFixCeil) == addClearFixCeil ) {
            $(this).after("<div style='clear:both'></div>");
        }
    });
});
$(function() {
    $('a[rel*=leanModal]').leanModal({ top : 200, closeButton: ".modal_close" });
});
$(function(){
    $('#slides').slides({
        preload: true,
        preloadImage: 'img/loading.gif',
        play: 5000,
        pause: 2500,
        hoverPause: true,
        animationStart: function(){
            $('.caption').animate({
                bottom:-35
            },100);
        },
        animationComplete: function(current){
            $('.caption').animate({
                bottom:0
            },500);

        }
    });
});